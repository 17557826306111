body {
  
  background-image: url(./components/backgrounds/back4.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin-bottom: 0;
}
@media only screen and (max-width: 1050px) {
  body {
    
    background-image: url(./components/backgrounds/back4.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: initial;
  }
}


